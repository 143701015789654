<script setup lang="ts">
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

withDefaults(
  defineProps<{
    color?: string
    icon: string
    closable?: boolean
  }>(),
  {
    color: 'blue',
    closable: false,
  }
)

const hide = ref(false)

function onHide() {
  hide.value = true
}
</script>

<template>
  <div
    v-if="!hide"
    class="flex justify-between space-x-3 rounded-sm border px-3 py-1"
    :class="{
      'border-blue-700 bg-blue-50 text-blue-700': color === 'blue',
      'border-red-700 bg-red-50 text-red-700': color === 'red',
    }"
  >
    <base-icon :svg="icon" class="h-6 w-6" />
    <div class="flex-1">
      <slot></slot>
    </div>
    <base-button
      v-if="closable"
      icon="heroicons/outline/x-mark"
      alt="close"
      class="h-6 w-6 rounded-full"
      @click="onHide"
    />
  </div>
</template>
