<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'

const emit = defineEmits<{
  selectPage: (page: number) => void
}>()

const props = defineProps<{
  currentPage: number
  totalPages: number
}>()

function getPages() {
  const pages = []

  if (props.totalPages < 10) {
    for (let i = 1; i <= props.totalPages; i++) {
      pages.push(i)
    }
  } else {
    if (props.currentPage <= 5) {
      for (let i = 1; i <= props.currentPage + 2; i++) {
        pages.push(i)
      }
      pages.push('...')
      for (let i = props.totalPages - 2; i <= props.totalPages; i++) {
        pages.push(i)
      }
    } else if (props.totalPages - 4 <= props.currentPage) {
      for (let i = 1; i <= 3; i++) {
        pages.push(i)
      }
      pages.push('...')
      for (let i = props.currentPage - 2; i <= props.totalPages; i++) {
        pages.push(i)
      }
    } else {
      for (let i = 1; i <= 3; i++) {
        pages.push(i)
      }
      pages.push('...')
      pages.push(props.currentPage - 1)
      pages.push(props.currentPage)
      pages.push(props.currentPage + 1)
      pages.push('...')
      for (let i = props.totalPages - 2; i <= props.totalPages; i++) {
        pages.push(i)
      }
    }
  }

  return pages
}

function selectPage(page: number) {
  if (!isNaN(page)) {
    emit('selectPage', page)
  }
}

function previousPage() {
  if (props.currentPage > 1) {
    emit('selectPage', props.currentPage - 1)
  }
}

function nextPage() {
  if (props.currentPage < props.totalPages) {
    emit('selectPage', props.currentPage + 1)
  }
}
</script>

<template>
  <div class="flex justify-between border-t text-sm font-medium">
    <div
      class="flex cursor-pointer items-center space-x-3 py-4 transition-all hover:opacity-50 active:opacity-75"
      @click="previousPage"
    >
      <base-icon svg="plain/arrow-narrow-left" :size="20" />
      <span>Previous</span>
    </div>
    <div class="flex text-base">
      <div
        v-for="(i, index) in getPages()"
        :key="index"
        class="flex h-9 w-12 cursor-pointer items-end justify-center transition-all hover:opacity-50 active:opacity-75"
        :class="{
          'border-t-2 border-primary text-primary': i === currentPage,
        }"
        @click="selectPage(i)"
      >
        {{ i }}
      </div>
    </div>
    <div
      class="flex cursor-pointer items-center space-x-3 py-4 transition-all hover:opacity-50 active:opacity-75"
      @click="nextPage"
    >
      <span>Next</span>
      <base-icon svg="plain/arrow-narrow-right" :size="20" />
    </div>
  </div>
</template>
